@mixin fontFace($font, $folder, $sizes, $ext) {
    @each $size in $sizes {
        @font-face {
            font-family: $font;
            src: url('../../fonts/#{$folder}/#{$folder}-#{$size}.#{$ext}');
            font-style: normal;
            font-weight: $size;
            font-display: swap;
        }
    }
}

/* Webfont: BP Theinhardt */
$default-font-name: 'BP-Theinhardt';
$default-font-folder: 'BPTheinhardt';
$default-font-sizes: 400, 700;

// NB Architekt
$alt-font-name: 'NB-Architekt';
$alt-font-folder: 'NBArchitekt';
$alt-font-sizes: 400;

@include fontFace($default-font-name, $default-font-folder, $default-font-sizes, 'woff');
@include fontFace($alt-font-name, $alt-font-folder, $alt-font-sizes, 'otf');